import { FilterMenu, MainPageCards, WizardCard } from '../models/Home';
import { GroupRoles, GroupTypes, MainGroupRoles, UserFormatType } from './AdminGroups';

export enum FilterType {
  SCHEDULE = 'SCHEDULE',
  FACILITATOR = 'FACILITATOR',
  SIZE = 'STAGE_SIZE',
  INDUSTRY_AFFINITY = 'INDUSTRY_AFFINITY',
  REGION = 'REGION',
  STUDY = 'STUDY',
  MAINLAND = 'MAINLAND',
  GROUP = 'GROUP',
  USER_STATUS = 'USER_STATUS',
  LAST_MODIFIED = 'LAST_MODIFIED',
  NEW_USERS = 'NEW_USERS',
  START_DATE_STUDY = 'START_DATE_STUDY',
  LOCATION = 'LOCATION',
  VIRTUAL_GROUPS = 'VIRTUAL_GROUPS',
  CHURCH = 'CHURCH',
  COHORT = 'COHORT',
  GROUP_OWNERS = 'GROUP_OWNERS',
  GROUP_AUDIENCE = 'GROUP_AUDIENCE',
  GROUP_LANGUAGE = 'GROUP_LANGUAGE',
  GROUP_INDUSTRY = 'GROUP_INDUSTRY',
  GROUP_STATE = 'GROUP_STATE',
}

export enum FilterUrlParams {
  DATE_AND_TIME = 'DATE_AND_TIME',
  FACILITATOR = 'FACILITATOR',
  SIZE = 'STAGE_OF_COMPANY',
  LOCATION = 'LOCATION',
  STUDY = 'STUDY',
  VIRTUAL_GROUPS = 'VIRTUAL_GROUPS',
  CHURCH = 'CHURCH',
}

export const SCHEDULE_DATA: FilterMenu = {
  text: 'Date and Time',
  code: FilterType.SCHEDULE,
  image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/schedule.png',
  description: 'I’d like to find a group that works best for my schedule.',
  helperQuestion: 'Which day(s) works best for you?',
  selected: false,
};

export const MONTH_SCHEDULE_DATA: FilterMenu = {
  text: 'Month',
  code: FilterType.SCHEDULE,
};

export const YEAR_SCHEDULE_DATA: FilterMenu = {
  text: 'YEAR',
  code: FilterType.SCHEDULE,
};

export const COHORT_SCHEDULE_DATA: FilterMenu = {
  text: 'COHORT',
  code: FilterType.SCHEDULE,
};

export const INDUSTRY_SCHEDULE_DATA: FilterMenu = {
  text: 'INDUSTRY',
  code: FilterType.SCHEDULE,
};

export const VIRTUAL_GROUPS_DATA: FilterMenu = {
  text: 'Online Groups',
  code: FilterType.VIRTUAL_GROUPS,
  image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/virtual+groups.jpeg',
  description: 'I’d like to find a group meeting online within my industry/affinity or globally.',
  helperQuestion: '',
  selected: false,
};

export const CHURCH_DATA: FilterMenu = {
  text: 'Church',
  code: FilterType.CHURCH,
  image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/church.png',
  description: 'Want to host at your church? Talk to our team on how to get started.',
  helperQuestion: 'Find the church host that best fits your background.',
  selected: false,
};

export const FACILITATOR_DATA: FilterMenu = {
  text: 'Facilitator',
  code: FilterType.FACILITATOR,
  image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/leader.png',
  description: 'Find the facilitator that best fits your background.',
  helperQuestion: 'Find the facilitator that best fits your background.',
  selected: false,
};

export const STAGE_SIZE_DATA: FilterMenu = {
  text: 'Stage of Company',
  secondaryText: 'Stage / Size',
  code: FilterType.SIZE,
  image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/company-size.png',
  description: 'Discover entrepreneurs that are at a similar size and stage in the journey.',
  helperQuestion: 'What Stage do you Prefer?',
  selected: false,
};

export const REGION_DATA: FilterMenu = {
  text: 'Location',
  code: FilterType.REGION,
  image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/region.png',
  description: 'I’d like to find a group meeting ﻿in my area in-person or online.',
  helperQuestion: 'Location Matters.',
  selected: false,
};

export const MAINLAND_DATA: FilterMenu = {
  text: 'Location',
  code: FilterType.MAINLAND,
  image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/region.png',
  description: 'I’d like to find a group meeting ﻿in my area in-person or online.',
  helperQuestion: 'Location Matters.',
  selected: false,
};

export const STUDY_DATA: FilterMenu = {
  text: 'Study',
  code: FilterType.STUDY,
  image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/study.png',
  description: 'Search topics specific to where you are and what you want to study.',
  helperQuestion: 'Which study(s) are you interested in?',
  selected: false,
};

export const GROUP_DATA: FilterMenu = {
  text: 'Group',
  code: FilterType.GROUP,
};

export const USER_STATUS_DATA: FilterMenu = {
  text: 'User Status',
  code: FilterType.USER_STATUS,
};

export const LAST_MODIFIED_DATA: FilterMenu = {
  text: 'Last modified',
  code: FilterType.LAST_MODIFIED,
};

export const NEW_USERS_DATA: FilterMenu = {
  text: 'New users',
  code: FilterType.NEW_USERS,
};

export const START_DATE_STUDY_DATA: FilterMenu = {
  text: 'Start date of Study',
  code: FilterType.START_DATE_STUDY,
};

export const MAIN_PAGE: MainPageCards[] = [
  {
    image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/entrepreneur_home.jpeg',
    text: 'Entrepreneur',
    enum: MainGroupRoles.ENTREPRENEUR,
  },
  {
    image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/investor_home.jpeg',
    text: 'Investor',
    enum: MainGroupRoles.INVESTOR,
  },
];

export const GROUP_TYPE_PAGE_MONTHLY_LINK: string = '/app/groups?filter=STUDY&sf=d6fab119-25db-4a37-bdfe-cc06626d5cfa&groupRole=entrepreneur';

export const INVESTOR_COMPANY_SIZE_CARDS: MainPageCards[] = [
  {
    image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/investor/non_accredited_investor_old.png',
    text: 'Qualified Purchaser',
    enum: GroupRoles.QUALIFIED_PURCHASER,
  },
  {
    image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/investor/accredited_investor.png',
    text: 'Accredited Investor',
    enum: GroupRoles.ACCREDITED_INVESTOR,
  },
  {
    image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/investor/fund_manager.png',
    text: 'Fund Manager',
    enum: GroupRoles.FUND_MANAGER,
  },
  {
    image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/investor/financial_advisor.png',
    text: 'Financial Advisor',
    enum: GroupRoles.FINANCIAL_ADVISOR,
  },
  {
    image: 'https://fde-group-finder-tool.s3.amazonaws.com/home/investor/non_accredited_investor_old.png',
    text: 'Non-Accredited Investor',
    enum: GroupRoles.NON_ACCREDITED_INVESTOR,
  },
];
export const USER_FORMAT_TYPES: WizardCard[] = [
  {
    text: "I'm new",
    enum: UserFormatType.NEW,
    description: 'Start with an 8-week <span class="bolder">Foundation Group</span>.',
    userMainType: MainGroupRoles.ENTREPRENEUR,
  },
  {
    text: "I'm returning",
    enum: UserFormatType.RETURNING,
    description: 'Join one of our <span class="bolder">Faith Driven Entrepreneur Groups</span> where you can continue meeting.',
    userMainType: MainGroupRoles.ENTREPRENEUR,
  },
  {
    text: "I'm new",
    enum: UserFormatType.NEW,
    description: 'Start with a 6-week <span class="bolder">Foundation Group</span>.',
    userMainType: MainGroupRoles.INVESTOR,
  },
  {
    text: "I'm returning",
    enum: UserFormatType.RETURNING,
    description: 'Join one of our <span class="bolder">Communities</span> where you can dive deeper into a topic of your choice.',
    userMainType: MainGroupRoles.INVESTOR,
  },
];

export const MEETING_TYPES: WizardCard[] = [
  {
    text: 'Online',
    enum: GroupTypes.VIRTUAL,
  },
  {
    text: 'In-Person',
    enum: GroupTypes.IN_PERSON,
  },
];
