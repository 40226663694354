<div class="container">
  <div class="footer-top"></div>
  <mat-divider></mat-divider>
  <div
    class="d-flex align-items-center align-items-md-start justify-content-center justify-content-md-between flex-md-row flex-column mt-3 external-links"
  >
    <div class="mx-md-2 mx-0 mb-md-0 mb-2">
      <div class="d-flex justify-content-md-start justify-content-center row font-weight-bold">
        <i class="d-flex align-items-center fab fa-youtube mr-2 ml-md-3 ml-0"></i>CLICK TO WATCH
      </div>
      <div class="mt-1">
        <div class="primary-color pointer" (click)="openModal(links.MEDIA_LINK_USER, 'What are Faith Driven Entrepreneur Groups?')">
          <u>What are Faith Driven Entrepreneur Groups?</u>
        </div>
      </div>
    </div>
    <div class="mx-md-2 mx-0 mb-md-0 mb-2">
      <div class="d-flex justify-content-md-start justify-content-center font-weight-bold mb-1">USEFUL LINKS</div>
      <div
        class="d-flex justify-content-md-start justify-content-center primary-color pointer"
        (click)="externalNavigate(links.LEARN_MORE_ABOUT_GROUPS)"
      >
        <u>Learn more about Foundation Groups</u>
      </div>
      <div
        *ngIf="!isJustFaciltator"
        class="d-flex justify-content-md-start justify-content-center primary-color pointer"
        (click)="externalNavigate(links.LEAD_A_GROUP)"
      >
        <u>Interested in Leading a Group?</u>
      </div>
      <div class="d-flex justify-content-md-start justify-content-center primary-color pointer" (click)="goToGroups(groupRole.ENTREPRENEUR)">
        <u>Go to Entrepreneur Groups</u>
      </div>
      <div class="d-flex justify-content-md-start justify-content-center primary-color pointer" (click)="goToGroups(groupRole.INVESTOR)">
        <u>Go to Investor Groups</u>
      </div>
    </div>
    <div class="mx-md-2 mx-0 mb-md-0 mb-2">
      <div class="d-flex justify-content-md-start justify-content-center font-weight-bold mb-1">NEED HELP?</div>
      <div class="d-flex justify-content-center justify-content-md-start">For support, email us at</div>
      <span class="d-flex justify-content-center justify-content-md-start">
        <u class="primary-color"
          ><a class="primary-color" href="mailto:groups@faithdrivenentrepreneur.org">groups&#64;faithdrivenentrepreneur.org</a></u
        ></span
      >
    </div>
  </div>
</div>
<div class="footer" [class.custom-footer-location]="currentUrl.indexOf('filter/locations') > -1 || currentUrl.indexOf('filter/date-time') > -1">
  <div class="d-flex align-items-center align-items-md-start justify-content-center justify-content-md-between flex-md-row flex-column container">
    <div class="d-flex justify-content-md-start justify-content-center mx-md-2 mx-0 mb-md-0 mb-2 mt-md-2">
      <img
        class="d-flex justify-content-md-start justify-content-center footer-logo pointer mt-4"
        src="https://fde-group-finder-tool.s3.amazonaws.com/logos/FDEFDI_logo_horizontal_FDEFDI_white_horizontal.png"
        alt="logo-img"
        (click)="goHome()"
      />
    </div>
    <div class="mx-md-2 mx-0 mt-md-4">
      <div class="d-flex justify-content-md-start justify-content-center">
        <div class="pointer mx-2" (click)="externalNavigate(links.LINK_FOOTER_TWITTER)">
          <img src="assets/icons/x.png" alt="X-Twitter" class="icon-size" />
        </div>
        <div class="pointer mx-2" (click)="externalNavigate(links.LINK_FOOTER_FACEBOOK)">
          <i class="fab fa-facebook-f gray-color fa-lg"></i>
        </div>
        <div class="pointer ml-2" (click)="externalNavigate(links.LINK_FOOTER_LINKEDIN)">
          <i class="fab fa-linkedin-in gray-color fa-lg"></i>
        </div>
      </div>
    </div>
  </div>
</div>
