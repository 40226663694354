<div class="d-flex flex-column root" [ngClass]="fromAdmin ? 'wide' : 'narrow'" *ngIf="multiFilter">
  <div class="d-flex justify-content-between px-2 py-3 header">
    <h2 class="">Filters</h2>
    <div class="uppercase primary-color font-weight-bold pointer" (click)="resetAllFilters()"><u>Remove all</u></div>
  </div>

  <mat-accordion class="example-headers-align" multi>
    <form [formGroup]="manager.multiFilterForm">
      <!-- Location -->
      <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.mainlands.expanded || !isAdminGroups">
        <mat-expansion-panel-header>
          <mat-panel-title> Location </mat-panel-title>
          <mat-panel-description *ngIf="multiFilter.mainlands.options?.length">
            <i
              class="fas fa-filter"
              [class.primary-color]="multiFilter.mainlands.expanded"
              appClickStopPropagation
              (click)="resetFilter(multiFilter.mainlands); closeRegionPanels()"
            ></i>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="admin-input">
          <mat-form-field appearance="outline">
            <input
              id="address"
              matInput
              ngx-google-places-autocomplete
              #placesRef
              [options]="placesOptions"
              (onAddressChange)="handleAddressChange($event)"
              placeholder="Search by city or zip code"
            />
          </mat-form-field>
        </div>
        <mat-expansion-panel
          *ngIf="isAdminGroups"
          #subRegionsPanel
          class="regions-panel"
          togglePosition="after"
          [expanded]="multiFilter.mainlands.helperData.subRegionsExpanded"
        >
          <mat-expansion-panel-header>
            <mat-panel-title> Regions/Subregions </mat-panel-title>
          </mat-expansion-panel-header>

          <ng-container *ngIf="multiFilter.mainlands.options?.length">
            <ng-container *ngFor="let subRegion of multiFilter.mainlands.options; trackBy: updateRegionsFn">
              <ng-container *ngIf="subRegion.uuid === regionTypes.ALL; else regionDropdown">
                <mat-checkbox color="primary" [value]="subRegion.uuid" (change)="globalGroupsChange($event)">
                  <span class="font-weight-bold font-16">{{ subRegion.name }}</span>
                </mat-checkbox>
              </ng-container>
              <ng-template #regionDropdown>
                <div class="d-flex flex-column region-expansion-panel">
                  <mat-expansion-panel #regionPanels [disabled]="subRegion.disabled" togglePosition="after" [expanded]="subRegion.expanded">
                    <mat-expansion-panel-header #panelHeader>
                      <mat-panel-title>
                        <mat-checkbox
                          color="primary"
                          [value]="subRegion.uuid"
                          [disabled]="subRegion.disabled"
                          [checked]="subRegion.selected"
                          (click)="panelHeader._toggle()"
                          (change)="filterChanged($event, multiFilter.mainlands)"
                        >
                          {{ subRegion.name }}
                        </mat-checkbox>
                      </mat-panel-title>
                    </mat-expansion-panel-header>
                    <div class="d-flex flex-column">
                      <ng-container *ngFor="let location of subRegion.options">
                        <mat-checkbox
                          class="ml-4"
                          color="primary"
                          [checked]="location.selected"
                          [value]="location.uuid"
                          (change)="locationChanged(location)"
                        >
                          <span class="truncated-single-line" [title]="location.name">{{ location.name }}</span>
                        </mat-checkbox>
                      </ng-container>
                    </div>
                  </mat-expansion-panel>
                </div>
              </ng-template>
            </ng-container>
          </ng-container>
        </mat-expansion-panel>
      </mat-expansion-panel>

      <!-- User Role -->
      <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.userRole.expanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Who are you? </mat-panel-title>
          <mat-panel-description>
            <i
              class="fas fa-filter"
              [class.primary-color]="multiFilter.userRole.expanded"
              appClickStopPropagation
              (click)="resetFilter(multiFilter.userRole)"
            ></i>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <mat-radio-group class="d-flex flex-column" (change)="filterChanged($event, multiFilter.userRole)">
          <ng-container *ngFor="let role of multiFilter.userRole.options">
            <mat-radio-button color="primary" [value]="role.uuid" [name]="role.helperText" [checked]="role.selected">
              {{ role.name }}
            </mat-radio-button>
          </ng-container>
        </mat-radio-group>

        <div class="d-flex flex-column ml-4" *ngIf="multiFilter.userRole.getValue() === userRoles.INVESTOR && isAdminGroups">
          <ng-container *ngFor="let type of multiFilter.investorTypes.options">
            <mat-checkbox color="primary" [checked]="type.selected" [value]="type.uuid" (change)="filterChanged($event, multiFilter.investorTypes)">{{
              type.name | enumToString | titlecase
            }}</mat-checkbox>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <ng-container *ngIf="isAdminGroups">
        <!-- Cohort -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.cohort.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Cohort </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.cohort.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.cohort)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input">
            <mat-form-field appearance="outline">
              <mat-select (valueChange)="filterChanged($event, multiFilter.cohort)" [value]="multiFilter.cohort.getValue()">
                <mat-option *ngFor="let cohort of multiFilter.cohort.options" [value]="cohort.uuid">{{ cohort.name }} </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <!-- Start Date -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.startDate.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Start Date </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.startDate.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.startDate)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input">
            <mat-form-field appearance="outline" class="date-input">
              <input
                name="startDate"
                placeholder="MM/DD/YYYY"
                matInput
                readonly
                [value]="multiFilter.startDate.value"
                [matDatepicker]="startDatePicker"
                (click)="startDatePicker.open()"
                (dateChange)="filterChanged($event, multiFilter.startDate)"
              />
              <mat-datepicker-toggle matSuffix [for]="startDatePicker"></mat-datepicker-toggle>
              <mat-datepicker #startDatePicker></mat-datepicker>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <!-- Group Status -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.unitStatuses.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Group Status </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.unitStatuses.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.unitStatuses)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="d-flex flex-column">
            <ng-container *ngFor="let format of multiFilter.unitStatuses.options">
              <mat-checkbox
                color="primary"
                [checked]="format.selected"
                [value]="format.uuid"
                (change)="filterChanged($event, multiFilter.unitStatuses)"
                >{{ format.name | enumToString | titlecase }}</mat-checkbox
              >
            </ng-container>
          </div>
        </mat-expansion-panel>
      </ng-container>

      <!-- Group Format by study -->
      <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.userType.expanded">
        <mat-expansion-panel-header>
          <mat-panel-title> User Type </mat-panel-title>
          <mat-panel-description>
            <i
              class="fas fa-filter"
              [class.primary-color]="multiFilter.userType.expanded"
              appClickStopPropagation
              (click)="resetFilter(multiFilter.userType)"
            ></i>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="d-flex flex-column">
          <ng-container *ngFor="let format of multiFilter.userType.options">
            <mat-checkbox color="primary" [checked]="format.selected" [value]="format.uuid" (change)="filterChanged($event, multiFilter.userType)">{{
              format.name | enumToString | titlecase
            }}</mat-checkbox>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <!-- Meeting Type -->
      <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.meetingType.expanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Meeting Type </mat-panel-title>
          <mat-panel-description>
            <i
              class="fas fa-filter"
              [class.primary-color]="multiFilter.meetingType.expanded"
              appClickStopPropagation
              (click)="resetFilter(multiFilter.meetingType)"
            ></i>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="d-flex flex-column">
          <ng-container *ngFor="let meetingType of multiFilter.meetingType.options">
            <mat-checkbox
              color="primary"
              [checked]="meetingType.selected"
              [value]="meetingType.uuid"
              [disabled]="meetingType.disabled"
              (change)="filterChanged($event, multiFilter.meetingType)"
              >{{ meetingType.name }}
            </mat-checkbox>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <!-- GROUP STATE-->
      <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.groupState.expanded">
        <mat-expansion-panel-header>
          <mat-panel-title> Group State </mat-panel-title>
          <mat-panel-description>
            <i
              class="fas fa-filter"
              [class.primary-color]="multiFilter.groupState.expanded"
              appClickStopPropagation
              (click)="resetFilter(multiFilter.groupState)"
            ></i>
          </mat-panel-description>
        </mat-expansion-panel-header>

        <div class="d-flex flex-column">
          <ng-container *ngFor="let groupState of multiFilter.groupState.options">
            <mat-checkbox
              color="primary"
              [checked]="groupState.selected"
              [value]="groupState.uuid"
              [disabled]="groupState.disabled"
              (change)="filterChanged($event, multiFilter.groupState)"
              >{{ groupState.name | titlecase }}
            </mat-checkbox>
          </ng-container>
        </div>
      </mat-expansion-panel>

      <ng-container *ngIf="isAdminGroups"><ng-container *ngTemplateOutlet="daysAndTime"></ng-container></ng-container>

      <div #advanceSearchSection class="d-flex flex-column">
        <div id="advancedSearchSection">
          <div class="d-flex justify-content-between mx-2 my-3">
            <h3 class="font-weight-bold">Advanced search</h3>
            <mat-slide-toggle
              class="example-margin"
              color="primary"
              formControlName="advancedSearch"
              (change)="advancedSearchClicked($event)"
            ></mat-slide-toggle>
          </div>
          <div class="advanced-sublabel ml-2">Filter your groups by industry/affinity, company size, or meeting day/time.</div>
        </div>
      </div>

      <ng-container *ngIf="manager.advancedSearch.value">
        <!-- Industry/Affinity -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.industryAffinity.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Industry/Affinity </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.industryAffinity.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.industryAffinity)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input">
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="industryAffinity"
                (valueChange)="filterChanged($event, multiFilter.industryAffinity)"
                [value]="multiFilter.industryAffinity.getValue()"
              >
                <mat-option
                  *ngFor="let industry of multiFilter.industryAffinity.options"
                  [value]="industry.uuid"
                  [disabled]="industry.name.startsWith('HEADER') || industry.name === 'DIVIDER'"
                >
                  <ng-container *ngIf="industry.name !== 'DIVIDER'; else dividerTemplate">
                    <span [ngStyle]="{ 'font-weight': industry.name.startsWith('HEADER') ? 'bold' : 'normal' }">
                      {{ industry.name | formatHeaderName }}
                    </span>
                  </ng-container>
                  <ng-template #dividerTemplate>
                    <hr />
                  </ng-template>
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <!-- Company Size -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.companySize.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Company Size </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.companySize.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.companySize)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input mb-2">
            <mat-form-field appearance="outline">
              <mat-select
                formControlName="companySize"
                (valueChange)="filterChanged($event, multiFilter.companySize)"
                [value]="multiFilter.companySize.getValue()"
              >
                <ng-container *ngFor="let item of multiFilter.companySize.options">
                  <mat-option [value]="item.uuid">
                    {{ item.name | formatHeaderName }}
                  </mat-option>
                </ng-container>
              </mat-select>
            </mat-form-field>
          </div>
        </mat-expansion-panel>

        <ng-container *ngIf="!isAdminGroups"><ng-container *ngTemplateOutlet="daysAndTime"></ng-container></ng-container>

        <ng-container *ngIf="isAdminGroups">
          <!-- Group Units Number -->
          <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.unitsCounter.expanded">
            <mat-expansion-panel-header>
              <mat-panel-title> Group Units </mat-panel-title>
              <mat-panel-description>
                <i
                  class="fas fa-filter"
                  [class.primary-color]="multiFilter.unitsCounter.expanded"
                  appClickStopPropagation
                  (click)="resetFilter(multiFilter.unitsCounter)"
                ></i>
              </mat-panel-description>
            </mat-expansion-panel-header>

            <div class="d-flex flex-column">
              <ng-container *ngFor="let format of multiFilter.unitsCounter.options">
                <mat-checkbox
                  color="primary"
                  [checked]="format.selected"
                  [value]="format.uuid"
                  (change)="filterChanged($event, multiFilter.unitsCounter)"
                  >{{ format.name | enumToString | titlecase }}</mat-checkbox
                >
              </ng-container>
            </div>
          </mat-expansion-panel>
        </ng-container>

        <!-- Churches -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.churches.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Church </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.churches.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.churches)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input mb-2">
            <mat-form-field appearance="outline">
              <input #inputChurchRef matInput formControlName="searchChurches" [matAutocomplete]="autoChurches" placeholder="Search church" />

              <mat-autocomplete #autoChurches="matAutocomplete" (optionSelected)="manager.selectedChurch($event, inputChurchRef)">
                <mat-option
                  *ngFor="let item of manager.searchResultsChurches | async"
                  [value]="item"
                  [disabled]="multiFilter.churches.optionInList(item.uuid)"
                >
                  {{ item.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div *ngFor="let church of multiFilter.churches.options" class="d-flex flex-column">
            <mat-checkbox color="primary" [value]="church.uuid" [checked]="church.selected" (change)="filterChanged($event, multiFilter.churches)">
              <span class="truncated-single-line">
                {{ church.name }}
              </span>
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <!-- Facilitators -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.facilitators.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Facilitator </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.facilitators.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.facilitators)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input mb-2">
            <mat-form-field appearance="outline">
              <input
                #inputFacilitatorRef
                matInput
                formControlName="searchFacilitators"
                [matAutocomplete]="autoFacilitators"
                placeholder="Search facilitator"
              />

              <mat-autocomplete #autoFacilitators="matAutocomplete" (optionSelected)="manager.selectedFacilitator($event, inputFacilitatorRef)">
                <mat-option
                  *ngFor="let item of manager.searchResultsFacilitators | async"
                  [value]="item"
                  [disabled]="multiFilter.facilitators.optionInList(item.uuid)"
                >
                  {{ item.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div *ngFor="let facilitator of multiFilter.facilitators.options" class="d-flex flex-column">
            <mat-checkbox
              color="primary"
              [value]="facilitator.uuid"
              [checked]="facilitator.selected"
              (change)="filterChanged($event, multiFilter.facilitators)"
            >
              <span class="truncated-single-line">
                {{ facilitator.name }}
              </span>
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <!-- Studies -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.studies.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Study </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.studies.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.studies)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input mb-2">
            <mat-form-field appearance="outline">
              <input #inputStudyRef matInput formControlName="searchStudies" [matAutocomplete]="autoStudies" placeholder="Search study" />

              <mat-autocomplete #autoStudies="matAutocomplete" (optionSelected)="manager.selectedStudy($event, inputStudyRef)">
                <mat-option
                  *ngFor="let item of manager.searchResultsStudies | async"
                  [value]="item"
                  [disabled]="multiFilter.studies.optionInList(item.uuid)"
                >
                  {{ item.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div *ngFor="let study of multiFilter.studies.options" class="d-flex flex-column">
            <mat-checkbox color="primary" [value]="study.uuid" [checked]="study.selected" (change)="filterChanged($event, multiFilter.studies)">
              <span class="truncated-single-line">
                {{ study.name }}
              </span>
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <!-- Group Audience -->
        <mat-expansion-panel *ngIf="isAdminGroups" togglePosition="before" [expanded]="multiFilter.groupAudience.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Audiences </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.groupAudience.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.groupAudience)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input mb-2">
            <mat-form-field appearance="outline">
              <input #inputAudienceRef matInput formControlName="searchAudiences" [matAutocomplete]="autoAudiences" placeholder="Search audience" />

              <mat-autocomplete #autoAudiences="matAutocomplete" (optionSelected)="manager.selectedAudience($event); inputAudienceRef.value = ''">
                <mat-option
                  *ngFor="let item of manager.searchResultsAudiences"
                  [value]="item"
                  [disabled]="multiFilter.groupAudience.optionInList(item.uuid)"
                >
                  {{ item.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div *ngFor="let audience of multiFilter.groupAudience.options" class="d-flex flex-column">
            <mat-checkbox
              color="primary"
              [value]="audience.uuid"
              [checked]="audience.selected"
              (change)="filterChanged($event, multiFilter.groupAudience)"
            >
              <span class="truncated-single-line">{{ audience.name }}</span>
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <!-- Group Owners -->
        <mat-expansion-panel *ngIf="isAdminGroups" togglePosition="before" [expanded]="multiFilter.groupOwners.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Owners </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.groupOwners.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.groupOwners)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input mb-2">
            <mat-form-field appearance="outline">
              <input #inputOwnersRef matInput formControlName="searchOwners" [matAutocomplete]="autoOwners" placeholder="Search owners" />

              <mat-autocomplete #autoOwners="matAutocomplete" (optionSelected)="manager.selectedOwner($event); inputOwnersRef.value = ''">
                <mat-option
                  *ngFor="let item of manager.searchResultsOwners"
                  [value]="item"
                  [disabled]="multiFilter.groupOwners.optionInList(item.uuid)"
                >
                  {{ item.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div *ngFor="let owner of multiFilter.groupOwners.options" class="d-flex flex-column">
            <mat-checkbox color="primary" [value]="owner.uuid" [checked]="owner.selected" (change)="filterChanged($event, multiFilter.groupOwners)">
              <span class="truncated-single-line">
                {{ owner.name }}
              </span>
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <!-- Group Language -->
        <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.groupLanguage.expanded">
          <mat-expansion-panel-header>
            <mat-panel-title> Languages </mat-panel-title>
            <mat-panel-description>
              <i
                class="fas fa-filter"
                [class.primary-color]="multiFilter.groupLanguage.expanded"
                appClickStopPropagation
                (click)="resetFilter(multiFilter.groupLanguage)"
              ></i>
            </mat-panel-description>
          </mat-expansion-panel-header>

          <div class="admin-input mb-2">
            <mat-form-field appearance="outline">
              <input #inputLanguageRef matInput formControlName="searchLanguages" [matAutocomplete]="autoLanguages" placeholder="Search language" />

              <mat-autocomplete #autoLanguages="matAutocomplete" (optionSelected)="manager.selectedLanguage($event); inputLanguageRef.value = ''">
                <mat-option
                  *ngFor="let item of manager.searchResultsLanguage"
                  [value]="item"
                  [disabled]="multiFilter.groupLanguage.optionInList(item.uuid)"
                >
                  {{ item.name }}
                </mat-option>
              </mat-autocomplete>
            </mat-form-field>
          </div>

          <div *ngFor="let language of multiFilter.groupLanguage.options" class="d-flex flex-column">
            <mat-checkbox
              color="primary"
              [value]="language.uuid"
              [checked]="language.selected"
              (change)="filterChanged($event, multiFilter.groupLanguage)"
            >
              <span class="truncated-single-line">{{ language.name }}</span>
            </mat-checkbox>
          </div>
        </mat-expansion-panel>

        <ng-container *ngIf="fromAdmin">
          <mat-checkbox
            color="primary"
            class="mx-4 my-2"
            *ngAccess-admin
            [checked]="!!multiFilter.archived.getValue()"
            (change)="$event.checked ? filterChanged($event.checked, multiFilter.archived) : resetFilter(multiFilter.archived)"
          >
            <h3 class="font-weight-bold mb-0 mr-3">Archived Groups</h3>
          </mat-checkbox>
        </ng-container>
      </ng-container>
    </form>
  </mat-accordion>
</div>

<ng-template #daysAndTime>
  <!-- Week Days -->
  <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.weekDays.expanded">
    <mat-expansion-panel-header>
      <mat-panel-title> Days of the Week </mat-panel-title>
      <mat-panel-description>
        <i
          class="fas fa-filter"
          [class.primary-color]="multiFilter.weekDays.expanded"
          appClickStopPropagation
          (click)="resetFilter(multiFilter.weekDays)"
        ></i>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="d-flex flex-column">
      <ng-container *ngFor="let day of multiFilter.weekDays.options">
        <mat-checkbox color="primary" [checked]="day.selected" [value]="day.uuid" (change)="filterChanged($event, multiFilter.weekDays)">{{
          day.name | titlecase
        }}</mat-checkbox>
      </ng-container>
    </div>
  </mat-expansion-panel>

  <!-- time intervals -->
  <mat-expansion-panel togglePosition="before" [expanded]="multiFilter.timeIntervals.expanded">
    <mat-expansion-panel-header>
      <mat-panel-title> Time </mat-panel-title>
      <mat-panel-description>
        <i
          class="fas fa-filter"
          [class.primary-color]="multiFilter.timeIntervals.expanded"
          appClickStopPropagation
          (click)="resetFilter(multiFilter.timeIntervals)"
        ></i>
      </mat-panel-description>
    </mat-expansion-panel-header>

    <div class="d-flex flex-column">
      <ng-container *ngFor="let interval of multiFilter.timeIntervals.options">
        <mat-checkbox
          color="primary"
          [checked]="interval.selected"
          [value]="interval.uuid"
          (change)="filterChanged($event, multiFilter.timeIntervals)"
          >{{ interval.name }}
        </mat-checkbox>
      </ng-container>
    </div>
  </mat-expansion-panel>
</ng-template>
