import { TitleCasePipe } from '@angular/common';
import { Address } from 'ngx-google-places-autocomplete/objects/address';
import { AdminCohort } from 'src/app/internal-app/admin/tags/tags.model';
import { GroupTypes, MainGroupRoles } from '../constants/AdminGroups';
import {
  CHURCH_DATA,
  COHORT_SCHEDULE_DATA,
  FACILITATOR_DATA,
  FilterType,
  INDUSTRY_SCHEDULE_DATA,
  MAINLAND_DATA,
  MONTH_SCHEDULE_DATA,
  REGION_DATA,
  SCHEDULE_DATA,
  STAGE_SIZE_DATA,
  STUDY_DATA,
  VIRTUAL_GROUPS_DATA,
  YEAR_SCHEDULE_DATA,
} from '../constants/Home';
import { SortValue } from '../constants/SortByOptions';
import { IndustryHelper } from '../helpers/Industry.helper';
import { Location } from '../models/Location';
import { Timezone } from './AdminGroups';
import { StudyTypeList } from './AdminStudies';
import { Filter, FilterOption, SortBy, SortOption } from './Filters';
import { UUIDName, UUIDNameRoleType } from './GenericObject';

export class GroupsFilters extends SortBy {
  days: Filter;
  dayIntervals: Filter;
  months: Filter;
  years: Filter;
  cohorts: Filter;
  startDate: string;
  companySize: Filter<FilterOption>;
  study: Filter<Study>;
  facilitators: Filter<Facilitator>;
  region: Filter<FilterOption, RegionData>;
  location: Filter<FilterOption, Location>;
  // TODO create a new Mainland class for Filter compatibility
  mainlands: Filter<MainlandFilter>;
  virtualGroup: Filter;
  churches: Filter<Church>;
  owners: Filter<FilterOption>;
  audience: Filter<FilterOption>;
  language: Filter<FilterOption>;
  selectedFilter: Filter;
  filtersArray: Filter<FilterOption, any>[];
  anythingSelected: boolean;
  groupsType: GroupTypes[];
  searchText: string;
  size: number;
  page: number;
  industryAffinity: Filter<FilterOption>;
  justAffinity: Filter<FilterOption>;
  groupState: Filter;

  constructor(dropdowns: Dropdowns, size: number = 10, page: number = 0) {
    super();
    this.days = new Filter(
      FilterType.SCHEDULE,
      dropdowns.meetingDayList.map((item: Day) => new FilterOption(item.id.toString(), new TitleCasePipe().transform(item.day), false)),
      SCHEDULE_DATA
    );
    this.dayIntervals = new Filter(
      FilterType.SCHEDULE,
      dropdowns?.dayIntervalList.map((item: DayInterval) => new FilterOption(item.id.toString(), item.interval, false)),
      SCHEDULE_DATA
    );
    this.months = new Filter(
      FilterType.SCHEDULE,
      dropdowns.monthList.map((item: Month) => new FilterOption(item.id.toString(), new TitleCasePipe().transform(item.month), false)),
      MONTH_SCHEDULE_DATA
    );
    this.years = new Filter(
      FilterType.SCHEDULE,
      dropdowns.yearList.map((item: Year) => new FilterOption(item.id.toString(), new TitleCasePipe().transform(item.year), false)),
      YEAR_SCHEDULE_DATA
    );
    this.cohorts = new Filter(
      FilterType.SCHEDULE,
      dropdowns.cohortList.map((item: AdminCohort) => new FilterOption(item.uuid, item.name, false)),
      COHORT_SCHEDULE_DATA
    );
    this.industryAffinity = new Filter(
      FilterType.INDUSTRY_AFFINITY,
      IndustryHelper.getIndustriesWithHeaders(dropdowns.industryCategoriesList).map(
        (item: Industry) => new Industry(item.uuid, item.name, item.type, false)
      ),
      INDUSTRY_SCHEDULE_DATA
    );
    this.justAffinity = new Filter(
      FilterType.INDUSTRY_AFFINITY,
      dropdowns.affinityCategoriesList.map((item: Industry) => new Industry(item.uuid, item.name, item.type, false)),
      INDUSTRY_SCHEDULE_DATA
    );
    this.companySize = new Filter(
      FilterType.SIZE,
      dropdowns.companySizeList.map((item: UUIDNameRoleType) => new FilterOption(item.uuid, item.name, false, item.groupRoleTypes)),
      STAGE_SIZE_DATA
    );
    this.owners = new Filter<FilterOption>(
      FilterType.GROUP_OWNERS,
      dropdowns.groupOwnerList.map((item: Owner) => new FilterOption(item.uuid, `${item.firstName} ${item.lastName}`, false))
    );
    this.audience = new Filter<FilterOption>(
      FilterType.GROUP_AUDIENCE,
      dropdowns.groupAudienceList.map((item: UUIDName) => new FilterOption(item.uuid, item.name, false))
    );
    this.language = new Filter<FilterOption>(
      FilterType.GROUP_LANGUAGE,
      dropdowns.languageList.map((item: Language) => new FilterOption(item.uuid, item.name, false))
    );
    this.groupState = new Filter(
      FilterType.GROUP_STATE,
      dropdowns.groupStateList.map((state: string) => new FilterOption(state, state, false))
    );
    this.facilitators = new Filter<Facilitator>(FilterType.FACILITATOR, [], FACILITATOR_DATA);
    this.study = new Filter<Study>(FilterType.STUDY, [], STUDY_DATA);
    this.churches = new Filter<Church>(FilterType.CHURCH, [], CHURCH_DATA);
    this.mainlands = new Filter<MainlandFilter>(FilterType.MAINLAND, [], MAINLAND_DATA);
    this.virtualGroup = new Filter(FilterType.VIRTUAL_GROUPS, [], VIRTUAL_GROUPS_DATA);
    this.region = new Filter<FilterOption, RegionData>(FilterType.REGION, [], REGION_DATA, new RegionData(new Address()));
    this.location = new Filter<FilterOption, Location>(FilterType.LOCATION, [], []);
    this.filtersArray = [this.region, this.virtualGroup, this.mainlands, this.days];
    this.selectedFilter = this.days;
    this.selectedFilter.misc.selected = true;
    this.groupsType = [GroupTypes.HYBRID];
    this.size = size;
    this.page = page;
    this.searchText = '';
    this.startDate = '';
    this.anythingSelected = false;
    this.setSortByOptions();
  }

  private setSortByOptions(): void {
    this.sortBy = [
      new SortOption(SortValue.NAME, null, false),
      new SortOption(SortValue.TIME, null, false),
      new SortOption(SortValue.FACILITATOR, null, false),
      new SortOption(SortValue.STAGE_SIZE, null, false),
      new SortOption(SortValue.PARTICIPANTS, null, false),
      new SortOption(SortValue.PENDING_USERS, 'desc', true),
    ];
  }

  setSelectedFilter(filterType: FilterType): void {
    if (filterType === FilterType.LOCATION) {
      this.selectedFilter = new Filter(filterType, null);
    } else {
      this.selectedFilter = this.filtersArray.find((filter) => filter.name === filterType) || this.days;
      this.selectedFilter.misc.selected = true;
    }
  }

  clearFilters(): void {
    this.days.filters.forEach((subFilter: FilterOption) => (subFilter.selected = false));
    this.days.selectAll = false;
    this.dayIntervals.filters.forEach((subFilter: FilterOption) => (subFilter.selected = false));
    this.dayIntervals.selectAll = false;
    this.months.filters.forEach((subFilter: FilterOption) => (subFilter.selected = false));
    this.months.selectAll = false;
    this.years.filters.forEach((subFilter: FilterOption) => (subFilter.selected = false));
    this.years.selectAll = false;
    this.companySize.filters.forEach((subFilter: FilterOption) => (subFilter.selected = false));
    this.companySize.selectAll = false;
    this.unselectMainFilters();
    this.study.clearFilter();
    this.facilitators.clearFilter();
    this.churches.clearFilter();
    this.region.clearFilter();
    this.mainlands.clearFilter();
    this.region.custom.clearLocation();
    this.startDate = '';
    this.page = 0;
    this.searchText = '';
  }

  unselectMainFilters(): void {
    this.filtersArray.forEach((filter) => (filter.misc.selected = false));
  }

  checkAnythingSelected(): void {
    this.anythingSelected =
      this.filtersArray.some((filter: Filter) => {
        return filter.filters.some((subFilter: FilterOption) => subFilter.selected);
      }) ||
      this.months.checkAnythingSelected() ||
      this.years.checkAnythingSelected() ||
      !!this.startDate ||
      !!this.region.custom.formattedAddress;
  }

  updateFiltersOrder(filter: Filter): void {
    let index = filter.filters.length - 1;
    let c = 0;
    while (index >= c) {
      const element = filter.filters[index];
      if (element.selected) {
        filter.filters.splice(index, 1);
        filter.filters.unshift(element);
        index++;
        c++;
      }
      index--;
    }
  }
}

export interface TextCode {
  text: string;
  code: FilterType;
}

export interface FilterMenu extends TextCode {
  secondaryText?: string;
  image?: string;
  description?: string;
  helperQuestion?: string;
  selected?: boolean;
}

export interface FacilitatorDetails {
  facilitatorUuid: string;
  firstName: string;
  lastName: string;
  title: string;
  profileImgUrl: string;
  hasGroups: boolean;
  upcoming: boolean;
}

export class Facilitator extends FilterOption implements FacilitatorDetails {
  facilitatorUuid: string;
  firstName: string;
  lastName: string;
  title: string;
  profileImgUrl: string;
  hasGroups: boolean;
  upcoming: boolean;
  visible: boolean;

  // prettier-ignore
  constructor(
    facilitator: FacilitatorDetails,
    selected = false
  ) {
    super(facilitator.facilitatorUuid, facilitator.firstName + ' ' + facilitator.lastName, selected);
    this.firstName = facilitator.firstName;
    this.lastName = facilitator.lastName;
    this.title = facilitator.title;
    this.profileImgUrl = facilitator.profileImgUrl;
    this.hasGroups = facilitator.hasGroups;
    this.upcoming = facilitator.upcoming;
    this.visible = true;
  }
}

export interface MainlandDetails {
  uuid: string;
  region: string;
  state: string;
}

export class MainlandFilter extends FilterOption implements MainlandDetails {
  region: string;
  state: string;

  // prettier-ignore
  constructor(
    mainland: MainlandDetails,
    selected = false
  ) {
    super(mainland.uuid, mainland.region + ' ' + mainland.state, selected);
    this.region = mainland.region;
    this.state = mainland.state;
  }
}

export interface StudyDetails {
  author: string;
  name: string;
  imgUrl: string;
  studyUuid: string;
  includedInGroups: boolean;
  description?: string;
  trailerLink: string;
}

export class Study extends FilterOption implements StudyDetails {
  author: string;
  imgUrl: string;
  studyUuid: string;
  includedInGroups: boolean;
  description?: string;
  trailerLink: string;

  constructor(study: StudyDetails, selected = false) {
    super(study.studyUuid, study.name, selected);
    this.imgUrl = study.imgUrl;
    this.author = study.author;
    this.description = study.description;
    this.includedInGroups = study.includedInGroups;
    this.trailerLink = study.trailerLink;
  }
}

export interface ChurchDetails {
  name: string;
  imageUrl: string;
  uuid: string;
  state?: string;
  region: string;
  headline: string;
}

export class Church extends FilterOption implements ChurchDetails {
  imageUrl: string;
  churchUuid: string;
  state?: string;
  region: string;
  headline: string;

  constructor(church: ChurchDetails, selected = false) {
    super(church.uuid, church.name, selected);
    this.imageUrl = church.imageUrl;
    this.state = church.state;
    this.region = church.region;
    this.headline = church.headline;
  }
}

export interface GroupDetails {
  groupUuid: string;
  groupName: string;
  startsOn: number;
  startsOnDateTime: string;
  endsOn: number;
  endsOnDateTime: string;
  durationMinutes: number;
  facilitators: string[];
}

export class Group extends FilterOption implements GroupDetails {
  groupUuid: string;
  groupName: string;
  startsOn: number;
  startsOnDateTime: string;
  endsOn: number;
  endsOnDateTime: string;
  durationMinutes: number;
  facilitators: string[];

  constructor(group: GroupDetails, selected = false) {
    super(group.groupUuid, group.groupName, selected);
    this.startsOn = group.startsOn;
    this.startsOnDateTime = group.startsOnDateTime;
    this.endsOn = group.endsOn;
    this.endsOnDateTime = group.endsOnDateTime;
    this.durationMinutes = group.durationMinutes;
    this.facilitators = group.facilitators;
  }
}

export interface Dropdowns {
  companySizeList: UUIDNameRoleType[];
  cohortList: AdminCohort[];
  meetingDayList: Day[];
  dayIntervalList: DayInterval[];
  monthList: Month[];
  yearList: Year[];
  studyList: StudyDrop[];
  timezones: Timezone[];
  groupAudienceList: UUIDName[];
  groupOwnerList: Owner[];
  languageList: Language[];
  industryCategoriesList: Industry[];
  affinityCategoriesList: Industry[];
  studyTypeList: StudyTypeList[];
  groupRoleTypesList: string[];
  groupStateList: string[];
}

export interface Day extends Id {
  day: string;
}

export interface DayInterval extends Id {
  interval: string;
}

export interface Month extends Id {
  month: string;
}

export interface Year extends Id {
  year: string;
}

export interface Id {
  id: string;
}

export interface StudyDrop extends UUIDNameRoleType {
  onboardingStudy: boolean;
  nrOfSession: number;
}

export interface Owner {
  firstName: string;
  lastName: string;
  uuid: string;
  noOfGroups: number;
}

export interface Language {
  languagePictureUrl: string;
  languagePictureUuid: string;
  name: string;
  uuid: string;
  nrOfGroups?: number;
  defaultLanguage: boolean;
}

export class Region {
  city: string;
  countryCode?: string;
  stateProvince: string;
  latitude: number;
  longitude: number;
  postalCode?: string;
  address?: string;

  constructor(
    city: string = '',
    countryCode: string = '',
    stateProvince: string = '',
    postalCode: string = '',
    latitude: number = 0,
    longitude: number = 0
  ) {
    this.city = city;
    this.countryCode = countryCode;
    this.stateProvince = stateProvince;
    this.latitude = latitude;
    this.longitude = longitude;
    this.postalCode = postalCode;
  }
}

export class RegionData {
  address: Address;
  region: Region;
  formattedAddress: string;

  constructor(address: Address) {
    this.address = address;
    this.region = new Region();
    this.formattedAddress = '';
  }

  clearLocation(): void {
    this.address = new Address();
    this.region = new Region();
    this.formattedAddress = '';
  }
}

export interface MainPageCards {
  image: string;
  text: string;
  enum: string;
  unavailableText?: string;
}

export class Industry extends FilterOption {
  declare uuid: string;
  declare name: string;
  declare type: string;

  constructor(uuid: string, name: string, type: string, selected: boolean) {
    super(uuid, name, selected);
    this.uuid = uuid;
    this.name = name;
    this.type = type;
  }
}

export interface WizardCard {
  image?: string;
  text: string;
  enum: string;
  description?: string;
  userMainType?: MainGroupRoles;
}
